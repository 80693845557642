@font-face {
  font-family: 'Octavian Regular';
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/Octavian-Regular.eot?r-1810058");
  src: url("../src/fonts/Octavian-Regular.eot?#iefix?r-1810058") format("embedded-opentype"), 
       url("../src/fonts/Octavian-Regular.svg?v=20151022b#Octavian-Regular?r-1810058") format("svgz"),
       url("../src/fonts/Octavian-Regular.svg?v=20151022b#Octavian-Regular?r-1810058") format("svg"),
       url("../src/fonts/Octavian-Regular.woff?v=20151022b?r-1810058") format("woff"),
       url("../src/fonts/Octavian-Regular.woff?v=20151022b?r-1810058") format("woff")
}

@font-face {
  font-family: 'Octavian Medium';
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/Octavian-Medium.eot?r-1810058");
  src: url("../src/fonts/Octavian-Medium.eot?#iefix?r-1810058") format("embedded-opentype"), 
       url("../src/fonts/Octavian-Medium.svg?v=20151022b#Octavian-Medium?r-1810058") format("svgz"),
       url("../src/fonts/Octavian-Medium.svg?v=20151022b#Octavian-Medium?r-1810058") format("svg"),
       url("../src/fonts/Octavian-Medium.woff?v=20151022b?r-1810058") format("woff"),
       url("../src/fonts/Octavian-Medium.woff?v=20151022b?r-1810058") format("woff")
}

@font-face {
  font-family: 'Octavian Bold';
  font-style: normal;
  font-weight: normal;
  src: url("../src/fonts/Octavian-Bold.eot?r-1810058");
  src: url("../src/fonts/Octavian-Bold.eot?#iefix?r-1810058") format("embedded-opentype"),
       url("../src/fonts/Octavian-Bold.svg?v=20151022b#octavian-bold?r-1810058") format("svgz"),
       url("../src/fonts/Octavian-Bold.svg?v=20151022b#octavian-bold?r-1810058") format("svg"),
       url("../src/fonts/Octavian-Bold.woff?v=20151022b?r-1810058") format("woff"),
       url("../src/fonts/Octavian-Bold.woff?v=20151022b?r-1810058") format("woff")
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 100;
  font-display: swap;
  src: url("../src/fonts/Inter-Thin.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-Thin.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 100;
  font-display: swap;
  src: url("../src/fonts/Inter-ThinItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-ThinItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 200;
  font-display: swap;
  src: url("../src/fonts/Inter-ExtraLight.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-ExtraLight.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 200;
  font-display: swap;
  src: url("../src/fonts/Inter-ExtraLightItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-ExtraLightItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 300;
  font-display: swap;
  src: url("../src/fonts/Inter-Light.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-Light.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 300;
  font-display: swap;
  src: url("../src/fonts/Inter-LightItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-LightItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 400;
  font-display: swap;
  src: url("../src/fonts/Inter-Regular.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-Regular.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 400;
  font-display: swap;
  src: url("../src/fonts/Inter-Italic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-Italic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 500;
  font-display: swap;
  src: url("../src/fonts/Inter-Medium.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-Medium.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 500;
  font-display: swap;
  src: url("../src/fonts/Inter-MediumItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-MediumItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 600;
  font-display: swap;
  src: url("../src/fonts/Inter-SemiBold.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-SemiBold.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 600;
  font-display: swap;
  src: url("../src/fonts/Inter-SemiBoldItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-SemiBoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 700;
  font-display: swap;
  src: url("../src/fonts/Inter-Bold.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-Bold.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 700;
  font-display: swap;
  src: url("../src/fonts/Inter-BoldItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-BoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 800;
  font-display: swap;
  src: url("../src/fonts/Inter-ExtraBold.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-ExtraBold.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 800;
  font-display: swap;
  src: url("../src/fonts/Inter-ExtraBoldItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-ExtraBoldItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'Inter';
  font-style:  normal;
  font-weight: 900;
  font-display: swap;
  src: url("../src/fonts/Inter-Black.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-Black.woff?v=3.13") format("woff");
}
@font-face {
  font-family: 'Inter';
  font-style:  italic;
  font-weight: 900;
  font-display: swap;
  src: url("../src/fonts/Inter-BlackItalic.woff2?v=3.13") format("woff2"),
       url("../src/fonts/Inter-BlackItalic.woff?v=3.13") format("woff");
}

@font-face {
  font-family: 'navIcons';
  src:  url('../src/fonts/navIcons.eot?dg2j9l');
  src:  url('../src/fonts/navIcons.eot?dg2j9l#iefix') format('embedded-opentype'),
        url('../src/fonts/navIcons.ttf?dg2j9l') format('truetype'),
        url('../src/fonts/navIcons.woff?dg2j9l') format('woff'),
        url('../src/fonts/navIcons.svg?dg2j9l#navIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="nav-"], [class*=" nav-"] {
  font-family: 'navIcons' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nav-up:before {
  content: "\e900";
}
.nav-left:before {
  content: "\e901";
}
.nav-down:before {
  content: "\e902";
}
.nav-right:before {
  content: "\e903";
}

:root {
  --main-color: #222;
  --accent-color: #01aba8;
  --secondary-accent-color: #ffbf00;
  --page-background: #fff;
  --background-color: #f5f5f5;
  --octavian-regular: 'Octavian Regular', Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --octavian-medium: 'Octavian Medium', Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --octavian-bold: 'Octavian Bold', Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  --inter: 'Inter', Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

::selection {
  color: #fff;
  background: var(--secondary-accent-color);
}
::-moz-selection {
  color: #fff;
  background: var(--secondary-accent-color);
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Inter', Helvetica, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
}
